import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Services from 'src/pages/services';

import AuthRoute from './authRoute';

const Home = lazy(() => import('../pages/home'));
const Orders = lazy(() => import('../pages/orders'));
const Trade = lazy(() => import('../pages/trade'));
const Credit = lazy(() => import('../pages/credit'));
const CreditPackage = lazy(() => import('../pages/creditPackage'));
const Profile = lazy(() => import('../pages/profile'));
const TrackingV2 = lazy(() => import('../pages/tracking/v2'));
const Torq = lazy(() => import('../pages/torq'));
const WeatherSelectCity = lazy(() => import('../pages/weatherSelectCity'));
const Weather = lazy(() => import('../pages/weather'));
const FinancialPage = lazy(() => import('../pages/financial'));
const Quotation = lazy(() => import('../pages/quotation'));

const PrivateRoutes = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <AuthRoute type="private">
              <Home />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/orders"
          element={
            <AuthRoute type="private">
              <Orders />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/trade"
          element={
            <AuthRoute type="private">
              <Trade />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/credit"
          element={
            <AuthRoute type="private">
              <Credit />
            </AuthRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/user-profile"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/user-management"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/change-password"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/notification-preferences"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/notification-preferences/browser"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/profile/notification-preferences/mobile"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/tracking"
          element={
            <AuthRoute type="private">
              <TrackingV2 />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/torq"
          element={
            <AuthRoute type="private">
              <Torq />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/financial"
          element={
            <AuthRoute type="private">
              <FinancialPage />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/quotation"
          element={
            <AuthRoute type="private">
              <Quotation />
            </AuthRoute>
          }
        />
        <Route
          path="/__/credit-package"
          element={
            <AuthRoute type="private">
              <CreditPackage />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/weather-select-city"
          element={
            <AuthRoute type="private">
              <WeatherSelectCity />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard/weather"
          element={
            <AuthRoute type="private">
              <Weather />
            </AuthRoute>
          }
        />
        <Route
          path="/services"
          element={
            <AuthRoute type="private">
              <Services />
            </AuthRoute>
          }
        />
        <Route
          path="/__/notification-preferences"
          element={
            <AuthRoute type="private">
              <Profile />
            </AuthRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
