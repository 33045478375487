import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  width: 109px;
  height: 192px;
  border-radius: 3px;
  overflow: hidden;

  iframe {
    border: 0;
    width: 109px;
    height: 192px;
    border-radius: 3px;

    margin-top: -192px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const GrandietMask = styled(Grid)`
  width: 109px;
  height: 192px;
  background: linear-gradient(
    transparent,
    ${({ theme }) => theme.colors.white}
  );
  position: relative;
  z-index: 1;
  border-radius: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const Avatar = styled(Grid)`
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      )
      padding-box,
    linear-gradient(
        to top right,
        ${({ theme }) => theme.colors.primary},
        ${({ theme }) => theme.colors.quaternary}
      )
      border-box;
  border: 2px solid transparent;
`;

export const AvatarIcon = styled(Grid)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 22px;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`;
