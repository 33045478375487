import { ListCampaignsDTO } from '@sinagro/shared';
import React from 'react';

export type CurrentCampaignType = {
  index: number;
  iframeUrl: string;
};

export type CampaignsType = {
  campaigns: ListCampaignsDTO[];
  campaignsTotal: number;
  currentCampaignIndex: number;
  onChangeCurrentCampaignIndex?: (campaignIndex: number) => void;
  captureInteraction?: (campaignId: string, action: 'open' | 'click') => void;
  isLoading: boolean;
};

export const Campaigns = React.createContext<CampaignsType>({
  campaigns: [],
  campaignsTotal: 0,
  currentCampaignIndex: 0,
  isLoading: true,
});

export const useCampaignsContext = (): React.ContextType<
  React.Context<CampaignsType>
> => {
  return React.useContext(Campaigns);
};
