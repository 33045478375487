import { Grid, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  width: 100%;
  gap: 2px;
`;

export const Bar = styled(LinearProgress)`
  width: 100%;
  height: 4px;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }
`;
