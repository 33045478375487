import React from 'react';
import CampaignsProvider from 'src/providers/campaigns';

import CampaignsContent from './campaign';

const Campaigns = () => {
  return (
    <CampaignsProvider>
      <CampaignsContent />
    </CampaignsProvider>
  );
};

export default Campaigns;
