import { Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  border-radius: 8px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      background-color: transparent;
      padding-bottom: 0rem;
      padding-left: 0rem;
    `}
`;

export const Header = styled(Grid)<{ $isMobile: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? 0 : 0.5)}rem;
  padding-right: 1rem;
`;

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    font-weight: 600;
  }
`;

export const ButtonText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    font-weight: 600;
  }
`;

export const CampaignsList = styled(Grid)<{
  $centralize: boolean;
  $isMobile: boolean;
}>`
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  justify-content: ${({ $centralize }) =>
    $centralize ? 'center' : 'flex-start'};

  ::-webkit-scrollbar {
    width: 15px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 8px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-right: 1rem;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      ::-webkit-scrollbar-thumb:horizontal {
        width: 0px;
      }
    `}
`;

export const Stories = styled(Grid)`
  display: flex;
  gap: 0.5rem;
`;
