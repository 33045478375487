import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import { FeatureFlagDTO, FeatureFlagEnum } from '@sinagro/shared';
import { IconType } from 'react-icons';
import {
  MdHome,
  MdSwapVert,
  MdOutlineAttachMoney,
  MdDriveFileRenameOutline,
  MdPayments,
  MdLocalShipping,
  MdMoving,
  MdGridView,
  MdSource,
} from 'react-icons/md';

import { TorqIcon } from './styles';

export type MenuItemProps = {
  id: string;
  Icon?: IconType;
  label: string;
  url: string;
  hiddenCard?: boolean;
  featureFlag?: FeatureFlagEnum;
  color?: DefaultThemeColorsType;
  subMenu?: MenuItemProps[];
  badge?: boolean;
};

export const items = (isClientTorq: boolean): MenuItemProps[] => [
  {
    id: 'home',
    label: 'menu.home',
    Icon: MdHome,
    url: '/dashboard',
    hiddenCard: true,
  },
  {
    id: 'services',
    label: 'menu.services',
    Icon: MdGridView,
    url: '/services',
    subMenu: [
      {
        id: 'orders',
        label: 'menu.orders',
        Icon: MdDriveFileRenameOutline,
        url: '/dashboard/orders',
      },
      {
        id: 'trade',
        label: 'menu.trade',
        Icon: MdSwapVert,
        url: '/dashboard/trade',
      },
      {
        id: 'financial',
        label: 'menu.financialSubMenu.financial',
        Icon: MdPayments,
        url: '/dashboard/financial',
      },
      {
        id: 'credit',
        label: 'menu.credit',
        Icon: MdOutlineAttachMoney,
        url: '/dashboard/credit',
      },
      {
        id: 'creditPackage',
        label: 'menu.creditPackage',
        Icon: MdSource,
        url: '/__/credit-package',
        hiddenCard: true,
        featureFlag: FeatureFlagEnum.Documents,
      },
      {
        id: 'tracking',
        label: 'menu.tracking',
        Icon: MdLocalShipping,
        url: '/dashboard/tracking',
        hiddenCard: false,
        featureFlag: FeatureFlagEnum.TrackingPage,
      },
      {
        id: 'quotation',
        label: 'menu.quotation',
        Icon: MdMoving,
        url: '/dashboard/quotation',
        hiddenCard: false,
        featureFlag: FeatureFlagEnum.QuotationPage,
      },
      {
        id: 'torq',
        label: 'menu.torq',
        Icon: TorqIcon,
        url: isClientTorq
          ? '/dashboard/torq'
          : '/dashboard/weather-select-city',
        hiddenCard: true,
        featureFlag: FeatureFlagEnum.TorqPage,
      },
    ],
  },
];

export const menuItemsFeatureFlag = (
  items: MenuItemProps[],
  featureFlag: FeatureFlagDTO
) => {
  const verifiedItems = items.filter(
    (item) => item.featureFlag === undefined || featureFlag[item.featureFlag]
  );

  return verifiedItems;
};
