export * from './auth';
export * from './changePassword';
export * from './credit';
export * from './creditPackage';
export * from './errors';
export * from './filters';
export * from './financial';
export * from './financialOpenCredits';
export * from './financialPayable';
export * from './financialReceivable';
export * from './forgotPassword';
export * from './harvestReference';
export * from './integrationConfig';
export * from './internetConnection';
export * from './notification';
export * from './orders';
export * from './signUp';
export * from './trade';
export * from './user';
export * from './news';
export * from './userInvite';
export * from './userProfile';
export * from './validationCode';
export * from './tracking';
export * from './torq';
export * from './featureFlag';
export * from './campaigns';
export * from './report';
export * from './systemConfig';
export * from './quotation';
export * from './interaction';
export * from './weather';

export enum FetchStatusEnum {
  None = 'none',
  Fetching = 'fetching',
  Success = 'success',
  Failure = 'failure',
}

export enum FirebasePersistenceEnum {
  None = 'none',
  Local = 'local',
  Session = 'session',
}

export enum FinancialSelectorFilterEnum {
  Receivable = 'receivable',
  Payable = 'payable',
  OpenCredits = 'openCredits',
}

export enum ModalitySelectorFilterEnum {
  Barter = 'barter',
  Purchase = 'purchase',
}

export enum PaymentConditionSelectorFilterEnum {
  Barter = 'barter',
  InCash = 'in_cash',
  Installment = 'installment',
  Exchange = 'exchange',
  NoPayment = 'no_payment',
  FixedDate = 'fixed_date',
}

export enum CurrencySelectorFilterEnum {
  USD = 'USD',
  BRL = 'BRL',
}

export enum AnalyticsEventsEnum {
  AccountValidation = 'account_validation',
  DownloadInvoice = 'download_invoice',
  ScheduleAppointmentTech = 'visita_tecnica',
  ScheduleAppointmentStore = 'visita_loja',
  OrderTableColumnChange = 'colunas_pedido',
  OrderGroupyBy = 'filtro_agrupar_por',
  WhatsappLinkLogin = 'sac_whatsapp_login',
  WhatsappLinkSignUp = 'sac_whatsapp_cadastro',
  WhatsappLinkGeneral = 'sac_whatsapp_log',
  OrderShareReport = 'compartilhar_relatorio',
  CampaignOpen = 'campaign_open',
  CampaignClick = 'campaign_click',
  ModalLead = 'modal_lead',
  SquareQuotation = 'cotacao_praca',
  TrackingDownloadInvoice = 'entregas_download_nota_fiscal',
  TrackingDownloadReceipt = 'entregas_download_comprovante_entrega',
}
