import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import * as campaigns from './src/state/campaigns';
import * as changePassword from './src/state/changePassword';
import * as credit from './src/state/credit';
import * as creditPackage from './src/state/creditPackage';
import * as errors from './src/state/errors';
import * as featureFlag from './src/state/featureFlag';
import * as filters from './src/state/filters';
import * as financial from './src/state/financial';
import * as financialOpenCredits from './src/state/financialOpenCredits';
import * as financialPayable from './src/state/financialPayable';
import * as financialPayables from './src/state/financialPayable/v2';
import * as financialReceivable from './src/state/financialReceivable';
import * as financialReceivables from './src/state/financialReceivable/v2';
import * as forgotPassword from './src/state/forgotPassword';
import * as harvestReference from './src/state/harvestReference';
import * as integrationConfig from './src/state/integrationConfig';
import * as interaction from './src/state/interaction';
import * as internetConnection from './src/state/internetConnection';
import * as news from './src/state/news';
import * as notification from './src/state/notification';
import * as orders from './src/state/orders';
import * as quotation from './src/state/quotation';
import * as report from './src/state/report';
import * as signUp from './src/state/signUp';
import * as systemConfig from './src/state/systemConfig';
import * as torq from './src/state/torq';
import * as tracking from './src/state/tracking';
import * as remittances from './src/state/tracking/v2';
import * as trade from './src/state/trade';
import * as user from './src/state/user';
import * as userInvite from './src/state/userInvite';
import * as userProfile from './src/state/userProfile';
import * as validationCode from './src/state/validationCode';
import * as weather from './src/state/weather';

export const reducer = combineReducers({
  signUp: signUp.reducer,
  validationCode: validationCode.reducer,
  user: user.reducer,
  forgotPassword: forgotPassword.reducer,
  errors: errors.reducer,
  trade: trade.reducer,
  orders: orders.reducer,
  harvestReference: harvestReference.reducer,
  financial: financial.reducer,
  financialReceivable: financialReceivable.reducer,
  financialReceivables: financialReceivables.reducer,
  financialPayable: financialPayable.reducer,
  financialPayables: financialPayables.reducer,
  financialOpenCredits: financialOpenCredits.reducer,
  filters: filters.reducer,
  integrationConfig: integrationConfig.reducer,
  credit: credit.reducer,
  creditPackage: creditPackage.reducer,
  changePassword: changePassword.reducer,
  userProfile: userProfile.reducer,
  userInvite: userInvite.reducer,
  notification: notification.reducer,
  tracking: tracking.reducer,
  internetConnection: internetConnection.reducer,
  torq: torq.reducer,
  news: news.reducer,
  featureFlag: featureFlag.reducer,
  campaigns: campaigns.reducer,
  report: report.reducer,
  systemConfig: systemConfig.reducer,
  quotation: quotation.reducer,
  interaction: interaction.reducer,
  weather: weather.reducer,
  remittances: remittances.reducer,
});

export const actions = Object.freeze({
  signUp: signUp.actions,
  validationCode: validationCode.actions,
  user: user.actions,
  forgotPassword: forgotPassword.actions,
  errors: errors.actions,
  trade: trade.actions,
  orders: orders.actions,
  harvestReference: harvestReference.actions,
  financial: financial.actions,
  financialReceivable: financialReceivable.actions,
  financialReceivables: financialReceivables.actions,
  financialPayable: financialPayable.actions,
  financialPayables: financialPayables.actions,
  financialOpenCredits: financialOpenCredits.actions,
  integrationConfig: integrationConfig.actions,
  filters: filters.actions,
  credit: credit.actions,
  creditPackage: creditPackage.actions,
  changePassword: changePassword.actions,
  userProfile: userProfile.actions,
  userInvite: userInvite.actions,
  notification: notification.actions,
  tracking: tracking.actions,
  internetConnection: internetConnection.actions,
  torq: torq.actions,
  news: news.actions,
  featureFlag: featureFlag.actions,
  campaigns: campaigns.actions,
  report: report.actions,
  systemConfig: systemConfig.actions,
  quotation: quotation.actions,
  interaction: interaction.actions,
  weather: weather.actions,
  remittances: remittances.actions,
});

export const sagas = function* AllSagas() {
  yield all([
    ...signUp.sagas,
    ...validationCode.sagas,
    ...forgotPassword.sagas,
    ...trade.sagas,
    ...orders.sagas,
    ...harvestReference.sagas,
    ...financial.sagas,
    ...financialReceivable.sagas,
    ...financialReceivables.sagas,
    ...financialPayable.sagas,
    ...financialPayables.sagas,
    ...financialOpenCredits.sagas,
    ...integrationConfig.sagas,
    ...credit.sagas,
    ...creditPackage.sagas,
    ...changePassword.sagas,
    ...user.sagas,
    ...userProfile.sagas,
    ...userInvite.sagas,
    ...notification.sagas,
    ...tracking.sagas,
    ...internetConnection.sagas,
    ...torq.sagas,
    ...news.sagas,
    ...featureFlag.sagas,
    ...campaigns.sagas,
    ...report.sagas,
    ...systemConfig.sagas,
    ...quotation.sagas,
    ...interaction.sagas,
    ...weather.sagas,
    ...remittances.sagas,
  ]);
};
