import { Box, ButtonBase, Typography } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundBlack};
  display: flex;
  position: absolute;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(ButtonBase)`
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const CloseText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: 600;
  }
`;

export const CloseIcon = styled(MdClose)`
  color: ${({ theme }) => theme.colors.white};
`;
