import { InputProps, TextField } from '@material-ui/core';
import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';

import ErrorContainer from './errorContainer';
import { HelperTypography } from './styles';
import SuccessContainer from './successContainer';

interface FormTextFieldProps<T> extends UseControllerProps<T> {
  control: Control<T>;
  label: string;
  errorMessage?: string;
  type?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  inputProps?: InputProps;
  testID?: string;
  disabled?: boolean;
  variant?: 'standard' | 'outlined';
  placeholder?: string;
  helper?: string;
  success?: string;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  link?: {
    route: string;
    text: string;
  };
}

const FormTextField = <T extends FieldValues>(
  props: FormTextFieldProps<T>
): JSX.Element => {
  const {
    control,
    label,
    name,
    defaultValue,
    errorMessage,
    type,
    inputProps,
    testID,
    disabled,
    variant = 'standard',
    handleKeyPress,
    placeholder,
    helper,
    success,
    onBlur,
    link,
  } = props;

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            error={!!errorMessage}
            onKeyPress={handleKeyPress}
            type={type}
            style={{ width: '100%' }}
            InputProps={inputProps}
            inputProps={{ 'data-testid': testID }}
            disabled={disabled}
            variant={variant}
            placeholder={placeholder}
            onBlur={onBlur}
          />
        )}
      />
      {!!errorMessage && (
        <ErrorContainer message={errorMessage} icon={true} link={link} />
      )}
      {!!success && <SuccessContainer message={success} icon={true} />}
      {!!helper && <HelperTypography>{helper}</HelperTypography>}
    </React.Fragment>
  );
};

export default FormTextField;
