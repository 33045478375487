import React, { useCallback, useEffect, useState } from 'react';
import { useCampaignsContext } from 'src/contexts/campaigns';

import { Bar, Container } from './styles';

const ProgressBar = () => {
  const {
    campaigns,
    currentCampaignIndex,
    campaignsTotal,
    onChangeCurrentCampaignIndex,
  } = useCampaignsContext();
  const [progress, setProgress] = useState(0);

  const currentValue = (index: number) => {
    const value = index < currentCampaignIndex ? 100 : 0;
    return currentCampaignIndex === index ? progress : value;
  };

  const nextCampaign = useCallback(() => {
    if (onChangeCurrentCampaignIndex) {
      const lastCampaignIndex = campaignsTotal - 1;
      const nextCampaignIndex =
        currentCampaignIndex === lastCampaignIndex
          ? 0
          : currentCampaignIndex + 1;

      onChangeCurrentCampaignIndex(nextCampaignIndex);
    }
  }, [campaignsTotal, currentCampaignIndex, onChangeCurrentCampaignIndex]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return oldProgress + 1;
      });
    }, 50);

    if (progress === 100) {
      clearInterval(timer);
      return;
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  useEffect(() => {
    if (progress === 100) {
      nextCampaign();
      setProgress(0);
    }
  }, [nextCampaign, progress]);

  useEffect(() => {
    setProgress(0);
  }, [currentCampaignIndex]);

  return (
    <Container>
      {campaigns.map((_, index) => (
        <Bar variant="determinate" value={currentValue(index)} key={index} />
      ))}
    </Container>
  );
};

export default ProgressBar;
