import { ButtonBase, Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Grid)`
  display: flex;

  @media (max-height: 860px) {
    margin-top: 2rem;
  }
`;

export const Content = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 1rem;
  overflow: hidden;
  padding-bottom: 1rem;
`;

export const Header = styled(Grid)`
  display: flex;
  padding: 0.75rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const AvatarContent = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Avatar = styled(Grid)`
  width: 22px;
  height: 22px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 11px;
  align-items: center;
  justify-content: center;

  img {
    width: 14px;
    height: 13px;
  }
`;

export const AvatarText = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Story = styled(Grid)`
  width: 334px;
  height: 593px;
  border-radius: 0.5rem;
  overflow: hidden;

  iframe {
    border: 0;
    width: 334px;
    height: 593px;
    border-radius: 0.5rem;
  }

  div {
    width: 334px;
    height: 593px;
    position: absolute;
  }

  @media (max-height: 768px) {
    width: 240px;
    height: 426px;

    iframe {
      width: 240px;
      height: 426px;
    }

    div {
      width: 240px;
      height: 426px;
    }
  }
`;

export const ActionLeft = styled(Grid)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? 32 : 48)}px;
  display: flex;
  align-items: center;
  margin-right: 2rem;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      position: absolute;
      left: 0;
      top: 50%;
    `}
`;

export const ActionRight = styled(Grid)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? 32 : 48)}px;
  display: flex;
  align-items: center;
  margin-left: 2rem;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      position: absolute;
      right: 0;
      top: 50%;
    `}
`;

export const ActionButton = styled(ButtonBase)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? 32 : 48)}px;
  height: ${({ $isMobile }) => ($isMobile ? 32 : 48)}px;
  border-radius: 24px !important;
  background-color: ${({ theme, $isMobile }) =>
    $isMobile ? 'transparent' : theme.colors.textSecondary} !important;

  svg {
    color: ${({ theme }) => theme.colors.white};
    font-size: 24px;
  }
`;
