import { SagaIterator } from '@redux-saga/types';
import { InvoiceUrlDTO, Remittance, RemittanceReceipt } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  downloadInvoices,
  downloadReceipts,
  getGroupedRemittance,
  getRemittanceHistory,
} from '../../services/tracking';
import {
  DownloadInvoicesAction,
  DownloadReceiptsAction,
  FetchStatusEnum,
  GroupedRemittanceGetAction,
  RemittanceHistoryGetAction,
} from '../../types';
import {
  downloadRemittanceInvoices,
  downloadRemittanceReceipts,
} from '../../utils/downloadFiles';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getGroupedRemittance.type, getGroupedRemittanceEffectSaga),
  takeLatest(actions.getRemittanceHistory.type, getRemittanceHistoryEffectSaga),
  takeLatest(actions.downloadReceipts.type, downloadReceiptsEffectSaga),
  takeLatest(actions.downloadInvoices.type, downloadInvoicesEffectSaga),
];

export function* getGroupedRemittanceEffectSaga(
  action: GroupedRemittanceGetAction
): SagaIterator<void> {
  try {
    const groupedRemittance = yield call(getGroupedRemittance, action.payload);
    yield put(actions.groupedRemittance({ groupedRemittance }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export type RemittanceHistoryResponse = {
  results: Remittance[];
  total: number;
  page: number;
};

export function* getRemittanceHistoryEffectSaga(
  action: RemittanceHistoryGetAction
): SagaIterator<void> {
  try {
    const { results: remittanceHistory, total }: RemittanceHistoryResponse =
      yield call(getRemittanceHistory, action.payload);

    yield put(
      actions.remmitanceHistory({
        remittanceHistory,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusHistory({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    console.log(error);
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusHistory({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* downloadReceiptsEffectSaga(
  action: DownloadReceiptsAction
): SagaIterator<void> {
  try {
    const result: RemittanceReceipt[] = yield call(
      downloadReceipts,
      action.payload
    );
    yield call(downloadRemittanceReceipts, result);
    yield put(
      actions.updateStatusDownloadReceipts({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(
      actions.updateStatusDownloadReceipts({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* downloadInvoicesEffectSaga(
  action: DownloadInvoicesAction
): SagaIterator<void> {
  try {
    const result: InvoiceUrlDTO[] = yield call(
      downloadInvoices,
      action.payload
    );
    yield call(downloadRemittanceInvoices, action.payload.remittanceId, result);
    yield put(
      actions.updateStatusDownloadInvoices({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(
      actions.updateStatusDownloadInvoices({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
