import { QuotationCurrencyEnum } from '@sinagro/shared';

export const formatCentsToPrice = (
  centsValue: number,
  currency?: string | null,
  fractionDigits?: number | null
) => {
  if (!centsValue) return '-';
  const digits = fractionDigits ? fractionDigits : 2;
  const formattedCurrency = currency ? ` ${currency}` : '';
  return (
    (centsValue / 100).toLocaleString('pt-BR', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }) + formattedCurrency
  );
};

export const formatQuotation = (
  centsValue: number,
  currency: QuotationCurrencyEnum
) => {
  if (!centsValue) return '-';
  return (centsValue / 100).toLocaleString('pt-BR', {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency,
  });
};

export const formatMeasurementUnitToVolume = (
  quantityValue: number,
  measurementUnit?: string | null
) => {
  if (!quantityValue) return '-';
  const formattedMeasurementUnit = measurementUnit ? ` ${measurementUnit}` : '';
  const formattedQuantityValue = quantityValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedQuantityValue + formattedMeasurementUnit;
};

export const formatToTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatToLabelSelect = (label: string) => {
  return label.length > 12 ? `${label.slice(0, 12)}...` : label;
};

export const formatPhone = (phone: string) => {
  if (!phone) return;

  const formattedPhone = phone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})(\d)/, '($2) $3');

  return formattedPhone.length >= 14
    ? formattedPhone.replace(/(\d{5})(\d)/, '$1-$2')
    : formattedPhone.replace(/(\d{4})(\d)/, '$1-$2');
};

export const formatTemperature = (
  temperature: number | undefined,
  showUnit?: boolean
) => {
  if (!temperature && temperature !== 0) return '-';
  const roundTemperature = Math.round(temperature);
  const unit = showUnit ? 'c' : '';
  return `${roundTemperature}°${unit}`;
};

export const formatPrecipitation = (
  precipitation: number | undefined,
  fractionDigits?: number,
  hideUnit?: boolean
) => {
  if (!precipitation && precipitation !== 0) return '-';

  const unit = hideUnit ? '' : ' mm';

  if (fractionDigits) {
    const roundPrecipitation = precipitation.toFixed(fractionDigits);
    return `${roundPrecipitation}${unit}`;
  }

  const roundPrecipitation = Math.round(precipitation);
  return `${roundPrecipitation}${unit}`;
};

export const formatHumidity = (value: number | undefined) => {
  if (!value && value !== 0) return '-';
  const percentage = Math.round(value * 100);
  return `${percentage}%`;
};

export const formatWind = (value: number | undefined) => {
  if (!value && value !== 0) return '-';
  const wind = Math.round(value);
  return `${wind}`;
};

export const formatMeasurementUnit = (
  value: number | undefined,
  measurementUnit?: string | null,
  fractionDigits?: number | null
) => {
  if (!value && value !== 0) return '-';
  const digits = fractionDigits ? fractionDigits : 2;
  const formattedMeasurementUnit = measurementUnit ? ` ${measurementUnit}` : '';
  const formattedValue =
    value.toLocaleString('pt-BR', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }) + formattedMeasurementUnit;
  return formattedValue;
};
