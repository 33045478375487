import { Grid } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { NotificationWhatsAppContextEnum } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';
import DefaultSwitch from 'src/components/elements/switch';

import { actions, useDispatch, useSelector } from '../../../../state/store';

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userNotificationConfig = useSelector(
    (state) => state.shared.userProfile.userProfile.notificationConfig
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  const [config, setConfig] = useState(userNotificationConfig);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, context: string) => {
      setConfig({
        ...config,
        whatsapp: { ...config.whatsapp, [context]: event.target.checked },
      });

      dispatch(
        actions.shared.user.updateUserNotificationConfig({
          ...config,
          whatsapp: { ...config.whatsapp, [context]: event.target.checked },
        })
      );
    },
    [config, dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(actions.shared.userProfile.clear());
      dispatch(actions.shared.userProfile.getUserProfile());
    };
  }, [dispatch]);

  if (fetchStatus === FetchStatusEnum.Fetching) {
    return (
      <Grid container item justifyContent="center">
        <Loader size={28} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      justifyContent="center"
      style={{
        flexDirection: 'column',
        marginTop: '20px',
        marginBottom: '0px',
      }}
    >
      <DefaultSwitch
        onChange={handleOnChange}
        context={NotificationWhatsAppContextEnum.HubAlert}
        checked={config.whatsapp['hub:alert']}
        title={t('notifications.settings.whatsapp.hubAlert')}
        description={t('notifications.settings.whatsapp.description.hubAlert')}
        marginBottom={13}
      />

      <DefaultSwitch
        onChange={handleOnChange}
        context={NotificationWhatsAppContextEnum.HubReport}
        checked={config.whatsapp['hub:report']}
        title={t('notifications.settings.whatsapp.hubReport')}
        description={t('notifications.settings.whatsapp.description.hubReport')}
        marginBottom={13}
      />
    </Grid>
  );
};

export default Settings;
