import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdPlayArrow } from 'react-icons/md';
import Loader from 'src/components/elements/loader';
import Button from 'src/components/elements/materialUI/button';
import ModalFullScreen from 'src/components/elements/modalFullScreen';
import { useCampaignsContext } from 'src/contexts/campaigns';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import Player from './player';
import Story from './story';
import {
  CampaignsList,
  Container,
  Header,
  Title,
  ButtonText,
  Stories,
} from './styles';

const Campaigns = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const {
    campaigns,
    campaignsTotal,
    onChangeCurrentCampaignIndex,
    captureInteraction,
    isLoading,
  } = useCampaignsContext();
  const [isOpen, setIsOpen] = useState(false);

  const onWatch = (campaignIndex: number, campaignId: string) => {
    if (onChangeCurrentCampaignIndex && captureInteraction) {
      onChangeCurrentCampaignIndex(campaignIndex);
      captureInteraction(campaignId, 'open');
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  if (isLoading) {
    return (
      <Grid data-testid="loader-container" style={{ padding: '0.5rem' }}>
        <Loader size={24} />
      </Grid>
    );
  }

  if (!isLoading && campaignsTotal === 0) {
    return <></>;
  }

  return (
    <Container $isMobile={isMobile}>
      <Header $isMobile>
        <Title>{t('campaigns.title')}</Title>

        <Button
          onClick={() => onWatch(0, campaigns[0].campaignId)}
          color="primary"
          style={{ padding: '0.5rem 1rem' }}
          startIcon={<MdPlayArrow size={24} />}
        >
          <ButtonText>{t('campaigns.watch')}</ButtonText>
        </Button>
      </Header>

      <CampaignsList $centralize={campaignsTotal === 1} $isMobile={isMobile}>
        <Stories>
          {campaigns.map((item, index) => (
            <Story
              iframeUrl={item.iframeUrl}
              key={index}
              onClick={() => onWatch(index, item.campaignId)}
            />
          ))}
        </Stories>
      </CampaignsList>

      <ModalFullScreen
        open={isOpen}
        onClose={onClose}
        content={<Player onClose={onClose} />}
      />
    </Container>
  );
};

export default Campaigns;
