import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  FeatureFlagFetchAction,
  FeatureFlagSlice,
  FeatureFlagUpdateStatusAction,
} from '../../types';

const initialState: FeatureFlagSlice = {
  featureFlag: {
    torqPage: false,
    trackingPage: false,
    quotationPage: false,
    documentsPage: false,
  },
  fetchStatus: FetchStatusEnum.None,
};

export const slice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    clear: () => initialState,
    getFeatureFlag: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    featureFlag: (state, action: FeatureFlagFetchAction) => {
      state.featureFlag = action.payload.featureFlag;
    },
    updateStatus: (state, action: FeatureFlagUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
