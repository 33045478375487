export default {
  ['commons']: {
    ['title']: 'Portal do Cliente Sinova',
    ['logOut']: 'Sair',
    ['filter']: 'Filtrar',
    ['filters']: 'Filtros',
    ['cleanFilters']: 'Limpar filtros',
    ['until']: 'Até',
    ['portalTitle']: 'Portal GRUPO SINOVA',
    ['portalTitleHome']: 'Bem-vindo ao Portal do cliente SINOVA!',
    ['portalSubtitle']:
      'Aqui você pode  ver seus pedidos, acompanhar os contratos de trade, verificar valores de trade a receber, valores a pagar à Sinova e créditos em aberto.',
    ['delivered']: 'Entregue',
    ['report']: 'Relatório',
    ['print']: 'Imprimir',
    ['harvestReference']: 'Safra',
    ['total']: 'Total',
    ['goBack']: 'Voltar',
    ['details']: {
      ['more']: 'Mais detalhes',
      ['less']: 'Menos detalhes',
    },
    ['see']: {
      ['more']: 'ver mais',
      ['less']: 'ver menos',
    },
    ['confirm']: 'Confirmar',
    ['cancel']: 'Cancelar',
    ['close']: 'Fechar',
    ['copy']: 'Copiar',
    ['copied']: 'Copiado!',
    ['lastUpdate']: {
      ['success']: 'Última atualização às',
      ['failure']: 'Não foi possível verificar o horário da última atualização',
    },
    ['loadMore']: 'Carregar mais',
    ['needHelp']: 'Precisa de ajuda?',
    ['talkToUs']: 'Fale com a nossa equipe',
    ['editColumns']: {
      ['columns']: 'Colunas',
      ['apply']: 'Aplicar',
      ['invalidQuantity']: 'Ative pelo menos 3 colunas',
    },
    ['appliedFilters']: 'filtros aplicados',
    ['appliedFilter']: 'filtro aplicado',
    ['apply']: 'Aplicar',
    ['error']: {
      ['timeoutInternertConnection']:
        'Problemas com sua conexão com a internet',
    },
    ['downloading']: 'Iniciando download...',
    ['today']: 'Hoje',
    ['news']: {
      ['title']: 'Notícias',
      ['empty']: 'Nenhuma notícia encontrada',
      ['disclaimer']:
        'Notícias fornecidas por terceiros, e não refletem necessariamente a opinião do Grupo Sinova.',
    },
    ['hello']: 'Olá',
  },
  ['uploadContainer']: {
    ['buttonLabel']: 'Upload',
    ['buttonMobileLabel']: 'Enviar documentos',
    ['descriptionLabel']: 'Adicione os arquivos.',
    ['descriptionContent']: '(png, jpg ou pdf)',
  },
  ['login']: {
    ['title']: 'Login',
    ['submit']: 'Login',
    ['keepSession']: 'Continuar conectado',
    ['recoveryPassword']: 'Esqueceu sua senha?',
    ['noAccount']: 'Não possui uma conta?',
    ['signUp']: 'Cadastre-se',
    ['privacyPolicy']: 'Política de privacidade',
    ['termsOfUse']: 'Termos de uso',
    ['emailOrPhone']: 'E-mail ou Celular',
    ['password']: 'Senha',
    ['needHelp']: 'Precisa de ajuda?',
    ['talkToUs']: 'Fale com a gente',
    ['backofficeAccess']: 'Acesso funcionário Sinova - Backoffice',
  },
  ['signUp']: {
    ['title']: 'Escolha o tipo de registro',
    ['cpf']: 'Pessoa Física (CPF)',
    ['cnpj']: 'Pessoa Jurídica (CNPJ)',
    ['alreadyRegister']: 'Já é Cadastrado?',
    ['doLogin']: 'Faça Login',
    ['needHelp']: 'Precisa de ajuda?',
    ['talkToUs']: 'Fale com a gente',
  },
  ['signUpForm']: {
    ['email']: 'E-mail',
    ['phoneNumber']: 'Celular',
    ['chooseDocument']: 'Escolha seu tipo de documento',
    ['cpf']: 'CPF',
    ['cnpj']: 'CNPJ',
    ['name']: 'Nome',
    ['companyName']: 'Nome ou Razão Social',
    ['password']: 'Senha',
    ['confirmPassword']: 'Confirmação senha',
    ['signUp']: 'Cadastrar',
    ['policyAndTermsOfService']: {
      ['part1']: 'li e aceito as ',
      ['part2']: 'políticas de privacidade',
      ['part3']: ' e os ',
      ['part4']: 'termos de uso',
    },
    ['alreadyRegister']: 'Já é Cadastrado?',
    ['doLogin']: 'Faça Login',
    ['needHelp']: 'Precisa de ajuda?',
    ['talkToUs']: 'Fale com a gente',
    ['alreadyRegistered']: {
      ['email']: 'E-mail já cadastrado.',
      ['phoneNumber']: 'Telefone já cadastrado.',
      ['document']: 'Documento já cadastrado.',
      ['goToLogin']: 'Acesse sua conta aqui.',
    },
  },
  ['inviteSignUp']: {
    ['button']: 'Cadastrar',
    ['subtitle']:
      'Faça seu cadastro para acessar o Portal do Cliente como convidado:',
    ['alreadyRegistered']:
      'Caso já tenha feito seu cadastro, basta fazer login e poderá acessar todos dados do usuário que lhe enviou o convite.',
  },
  ['form']: {
    ['required']: 'Campo Obrigatório',
    ['min']: '{{field}} deve possuir no mínimo {{min}} caracteres',
    ['max']: '{{field}} deve possuir no máximo {{max}} caracteres',
    ['minMax']: '{{field}} deve possuir entre {{min}} e {{max}} caracteres',
    ['invalidFormat']: 'Formato inválido',
    ['invalidField']: '{{field}} inválido',
    ['confirmPassword']: 'Senhas devem ser iguais',
    ['cantBeTheSamePassword']: 'Senha nova não deve ser igual a antiga',
    ['atLeastOneLowercase']: 'Senha deve possuir no mínimo 1 letra minúscula',
    ['atLeastOneUppercase']: 'Senha deve possuir no mínimo 1 letra maiúscula',
    ['atLeastOneNumber']: 'Senha deve possuir no mínimo 1 número',
    ['atLeastOneSymbol']: 'Senha deve possuir no mínimo 1 caracter especial',
    ['notAcceptSpaces']: 'Senha não deve possuir espaços',
    ['passwordHelper']:
      'Digite uma senha de no mínimo 8 dígitos que contenha letra maiúscula, minúscula, número e caracter especial.',
  },
  ['validation']: {
    ['title']:
      'Para sua segurança, escolha em qual meio deseja receber um código de verificação',
    ['email']: 'E-mail',
    ['phone']: 'WhatsApp',
    ['signOut']: {
      ['ask']: 'Deseja sair?',
      ['confirm']: 'Sim',
      ['cancel']: 'Não',
    },
  },
  ['validationCode']: {
    ['title']: 'Validação',
    ['email']: 'Um email com um código de 6 dígitos foi enviado para: ',
    ['phone']: 'Um whatsapp com um código de 6 dígitos foi enviado para: ',
    ['sendAgain']: 'enviar novamente',
    ['submit']: 'Validar',
    ['backToLogin']: 'Voltar ao login',
    ['sendAgainIn']: 'enviar novamente em',
    ['secondsAbbrev']: 's',
    ['changeToEmail']: 'Receber via E-mail',
    ['changeToWhatsapp']: 'Receber via WhatsApp',
    ['changeButton']: 'Alterar',
  },
  ['validationCodeSuccess']: {
    ['title']: 'Sucesso!',
    ['subtitle']: 'Seu cadastro foi validado com sucesso',
    ['submit']: 'Acessar o portal',
  },
  ['forgotPassword']: {
    ['optionText']: 'Escolha por onde você deseja recuperar a senha',
    ['title']: 'Esqueci minha senha',
    ['subtitleEmail']:
      'Insira seu e-mail para receber o link de recuperação de senha',
    ['subtitleWhatsapp']:
      'Insira seu número do WhatsApp para receber o link de recuperação de senha',
    ['submit']: 'Confirmar',
    ['email']: 'E-mail',
    ['whatsapp']: 'WhatsApp',
    ['success']: 'Recuperação enviada com sucesso',
    ['returnToLogin']: 'Retornar ao login',
  },
  ['passwordRecovery']: {
    ['title']: 'Nova senha',
    ['submit']: 'Criar nova senha',
    ['newPassword']: 'Nova senha',
    ['confirmNewPassword']: 'Confirmar nova senha',
    ['success']: 'Senha alterada com sucesso',
  },
  ['menu']: {
    ['home']: 'Início',
    ['orders']: 'Pedidos',
    ['financial']: 'Financeiro',
    ['trade']: 'Trade',
    ['financialSubMenu']: {
      ['receivable']: 'A receber',
      ['payable']: 'A pagar',
      ['financial']: 'Financeiro',
      ['openCredits']: 'Créditos em aberto',
    },
    ['credit']: 'Crédito',
    ['creditPackage']: 'Documentos',
    ['profile']: 'Perfil',
    ['tracking']: 'Entregas',
    ['torq']: 'TORQ',
    ['quotation']: 'Preços Ref.',
    ['services']: 'Serviços',
  },
  ['header']: {
    ['myProfile']: 'Meus dados',
    ['changePassword']: 'Alterar senha',
    ['userManagement']: 'Gestão de usuários',
    ['notificationPreferences']: 'Configurar notificações',
    ['userInvite']: 'Convidar usuário',
    ['identificationNumbers']: 'Números de identificação Sinova',
    ['managerContact']: 'Contato do gerente',
    ['notificationSettings']: 'Gerenciar notificações',
    ['seeAsAlert']: 'Visualizando como: {{name}}. ID SAP: {{id}}',
  },
  ['routes']: {
    ['home']: 'Início',
    ['trade']: 'Trade',
    ['orders']: 'Pedidos',
    ['financial']: 'Financeiro',
    ['receivable']: 'A receber',
    ['payable']: 'A pagar',
    ['openCredits']: 'Créditos em aberto',
    ['credit']: 'Crédito',
    ['creditPackage']: 'Documentos',
    ['profile']: 'Perfil',
    ['userProfile']: 'Meu dados',
    ['userManagement']: 'Gestão de usuários',
    ['changePassword']: 'Alterar senha',
    ['notificationPreferences']: 'Configurar notificações',
    ['notification']: 'Notificações',
    ['tracking']: 'Entregas',
    ['torq']: 'TORQ',
    ['quotation']: 'Preços de Referência',
    ['services']: 'Serviços',
    ['weatherSelectCity']: 'TORQ',
    ['weather']: 'TORQ',
  },
  ['profile']: {
    ['tab']: {
      ['userProfile']: 'Meus dados',
      ['userManagement']: 'Gestão de usuários',
      ['changePassword']: 'Alterar senha',
      ['notificationPreferences']: 'Configurar notificações',
    },
    ['titleProfile']: 'Seus dados no Portal:',
    ['titleClientInfos']: 'Dados cliente Sinova:',
    ['titlePasswordChange']: 'Alterar senha de acesso ao Portal',
    ['subtitlePasswordChange']:
      'Essa alteração é válida para acesso Web e para aplicativo',
    ['profileForm']: {
      ['email']: 'E-mail',
      ['phoneNumber']: 'Celular',
      ['cpf']: 'CPF',
      ['cnpj']: 'CNPJ',
      ['name']: 'Nome',
      ['saveButton']: 'Salvar dados',
      ['success']: 'Dados do perfil atualizados com sucesso',
      ['check']: 'Verificar',
      ['thirdProviderUser']: 'Usuário AD não tem permissão para editar perfil',
    },
    ['deleteAccount']: {
      ['delete']: 'Encerrar conta no Portal',
      ['title']:
        'Tem certeza que deseja encerrar sua conta no Portal do Cliente Sinova?',
      ['confirmTitle']: 'Digite sua senha para excluir a sua conta',
      ['description']: {
        ['part1']:
          'Ao deletar sua conta, você não terá mais acesso ao Portal. Todos os dados que forneceu no Portal serão apagados.',
        ['part2']:
          'Caso tenha adicionado usuários como convidados, eles também perderão acesso aos seus dados do  Portal.',
      },
      ['password']: 'Senha',
      ['confirm']: 'Encerrar conta no Portal',
      ['cancel']: 'Manter minha conta',
      ['success']: 'Sua conta no Portal foi deletada com sucesso.',
    },
    ['passwordForm']: {
      ['oldPassword']: 'Senha atual',
      ['newPassword']: 'Nova senha',
      ['confirmNewPassword']: 'Confirmação nova senha',
      ['saveButton']: 'Salvar senha',
      ['success']: 'Senha alterada com sucesso',
    },
    ['identificationNumbers']: {
      ['title']: 'Números de identificação:',
      ['myIds']: 'Meus IDs:',
      ['guestIds']: 'IDs como convidado:',
      ['empty']: {
        ['part1']:
          'Ainda não há nenhum ID Sinova vinculado a sua conta no Portal do Cliente.',
        ['part2']:
          'Se for nosso cliente, converse com nosso suporte via WhatsApp',
        ['part3']:
          'Caso vá acessar dados de outro cliente, peça para esta pessoa enviar um convite para seu e-mail via “Gestão de usuários”.',
      },
    },
    ['managerInfo']: {
      ['title']: 'Contato do gerente:',
      ['empty']: 'Ainda não há contatos de gerentes disponíveis.',
    },
    ['validationCode']: {
      ['title']: 'Encaminhamos um código de verificação para o seu {{type}}',
      ['text']: 'Digite o código abaixo para verificar seu {{type}}:',
      ['textEdit']:
        'Digite o código de verificação recebido para concluir a alteração e verificar seu {{type}}:',
      ['sendAgain']: 'Reenviar',
      ['seconds']: 'segundos',
      ['success']: 'Seu {{type}} foi validado com sucesso',
    },
    ['editField']: {
      ['newEmail']: 'Digite seu novo e-mail',
      ['email']: 'E-mail',
      ['newPhoneNumber']: 'Digite seu novo número de celular',
      ['phoneNumber']: 'Celular',
      ['newName']: 'Digite seu novo nome',
      ['name']: 'Nome',
      ['saveButton']: 'Salvar',
    },
  },
  ['userManagement']: {
    ['title']: 'Usuários',
    ['noUsers']:
      'Você ainda não adicionou usuários que podem acessar seu Portal do cliente Sinova.',
    ['sendNewInvite']: 'Envie seu primeiro convite:',
    ['newInvite']: 'Convidar',
    ['inviteUser']: {
      ['title']: 'Convidar usuário',
      ['description']:
        'O usuário convidado terá acesso a todas suas informações do Portal do Cliente Sinova.',
      ['emailInput']: 'E-mail',
      ['submitButton']: 'Enviar convite',
    },
    ['inviteUserSuccess']: {
      ['title']: 'Convite enviado com sucesso!',
      ['description']: {
        ['part1']:
          'Seu convidado deverá aceitar o convite recebido por e-mail e completar o cadastro pelo aplicativo ou em nosso Portal Web.',
        ['part2']:
          'Ao concluir o cadastro, terá acesso a todas suas informações do Portal do Cliente Sinova.',
      },
    },
    ['deleteInvite']: {
      ['title']: 'Remover acesso',
      ['description']:
        'Tem certeza que deseja remover o acesso deste usuário ao seu Portal Sinova?',
      ['cancel']: 'Cancelar',
      ['accept']: 'Sim, remover',
    },
  },
  ['trade']: {
    ['header']: {
      ['contract']: 'Contrato',
      ['open']: 'Em aberto',
      ['processed']: 'Entregue',
      ['canceled']: 'Cancelado',
      ['type']: 'Tipo',
      ['product']: 'Produto',
      ['harvestReference']: 'Safra',
    },
    ['card']: {
      ['seeMore']: 'Ver mais detalhes',
      ['seeLess']: 'Ver menos detalhes',
      ['report']: 'Relatório',
    },
    ['summary']: {
      ['product']: 'Produto:',
      ['contractPrice']: 'Valor do contrato:',
      ['quantity']: 'Quantidade:',
      ['canceledQuantity']: 'Qtde cancelada:',
      ['plannedDeliveryDate']: 'Entrega:',
      ['propertyName']: 'Fazenda:',
      ['tareUnitPrice']: 'Preço por saca:',
      ['paymentDate']: 'Data de pagamento:',
      ['totalDelivered']: 'Total entregue:',
      ['totalSettledQuantity']: 'Qtde Fixada:',
      ['balanceToBeSet']: 'Saldo a Fixar:',
    },
    ['summaryDetails']: {
      ['cargo']: 'Romaneios',
      ['totalDelivered']: 'Total entregue:',
      ['grossWeight']: 'Peso Bruto:',
      ['tareWeight']: 'Peso Tara:',
      ['subtotal']: 'Subtotal:',
      ['discount']: 'Descontos:',
      ['netWeight']: 'Peso Líquido:',
      ['empty']: 'Ainda não foram feitas entregas neste contrato',
      ['settlement']: 'Fixações realizadas',
      ['emptySettlement']: 'Nenhuma fixação encontrada',
    },
    ['fields']: {
      ['id']: 'Romaneio',
      ['vehiclePlate']: 'Placa',
      ['deliveredAt']: 'Data',
      ['grossWeight']: 'Peso Bruto',
      ['tareWeight']: 'Tara',
      ['netWeight']: 'Peso líquido',
      ['discountWeight']: 'Descontos',
      ['netWeightWithDiscount']: 'Peso líq. com descontos',
      ['document']: 'Documento',
      ['settlement']: {
        ['fixationDate']: 'Data',
        ['quantity']: 'Peso',
        ['valueInCents']: 'Valor por saca',
      },
    },
    ['doDownload']: 'Baixar documento',
    ['empty']: 'Nenhum contrato encontrado',
  },
  ['orders']: {
    ['header']: {
      ['dealId']: 'Negociação:',
      ['propertyName']: 'Fazenda:',
      ['totalValue']: 'Valor total',
      ['invoicedValue']: 'Valor faturado',
      ['paymentCondition']: 'Condição de pagamento:',
      ['dueDate']: 'Vencimento:',
    },
    ['card']: {
      ['seeMore']: 'Ver mais detalhes',
      ['seeLess']: 'Ver menos detalhes',
    },
    ['summary']: {
      ['defensive']: 'Defensivos:',
      ['fertilizer']: 'Fertilizantes:',
      ['seed']: 'Sementes:',
      ['specialty']: 'Especialidades:',
      ['animal_nutrition']: 'Nutrição animal:',
      ['grains']: 'Grãos:',
      ['unknow']: 'Desconhecido:',
      ['total']: 'Total:',
      ['itemSummary']: 'Resumo dos pedidos',
      ['ordersIn']: 'Pedidos em',
      ['order']: ' para o pedido {{order}}',
      ['segment']: ' do segmento {{segment}}',
      ['segments']: ' dos segmentos {{segment}}',
      ['propertyName']: ' para a fazenda {{propertyName}}',
      ['productName']: ' do produto {{productName}}',
      ['status']: ' que estão {{status}}',
      ['cultivation']: ' da cultura {{cultivation}}',
      ['harvestReference']: ' da safra {{harvestReferences}}',
      ['harvestReferences']: ' das safras {{harvestReferences}}',
      ['empty']: 'Nenhuma informação foi encontrada',
    },
    ['fields']: {
      ['dealId']: 'Negociação',
      ['orderId']: 'Pedido',
      ['orderDate']: 'Data',
      ['cultivation']: 'Cultura',
      ['propertyName']: 'Fazenda',
      ['productName']: 'Produto',
      ['productSegment']: 'Segmento',
      ['totalQuantity']: 'Volume total',
      ['totalValue']: 'Valor total',
      ['totalValueInCents']: 'Valor total',
      ['deliveredQuantity']: 'Volume entregue',
      ['invoicedValue']: 'Valor faturado',
      ['invoicedValueInCents']: 'Valor faturado',
      ['invoices']: 'Notas Fiscais',
      ['emptyInvoices']: 'Sem NF disponível',
      ['status']: 'Status',
      ['harvestReference']: 'Safra',
      ['abbreviated']: {
        ['invoices']: 'NF',
        ['totalQuantity']: 'Vol. total',
        ['deliveredQuantity']: 'Vol. entregue',
      },
      ['details']: 'Detalhes',
    },
    ['empty']: 'Nenhum pedido encontrado',
    ['groupBy']: {
      ['propertyName']: 'Fazenda',
      ['deal']: 'Negociação',
    },
    ['expandAll']: 'Expandir todos',
    ['hideAll']: 'Recolher todos',
    ['invoiceFields']: {
      ['id']: 'Número',
      ['quantity']: 'Volume',
      ['value']: 'Valor Faturado',
      ['dueDate']: 'Faturado em',
      ['download']: 'Baixar',
      ['invoiceDownload']: 'Baixar NF',
      ['empty']: 'Ainda não há notas fiscais disponíveis',
    },
    ['invoiceOrder']: 'Notas Fiscais Pedido {{orderId}}',
    ['shareReport']: 'Compartilhar relatório',
    ['reportMessage']:
      'Portal do Cliente Sinova:  Aqui está seu Relatório de Pedidos. Clique no link para fazer download: \n\n',
    ['captionStatus']: {
      ['title']: 'Legenda - Status de Faturamento',
      ['open']: 'Ainda não houve faturamento deste item.',
      ['partial']: 'Volume parcialmente faturado.',
      ['finished']: 'Volume totalmente faturado.',
      ['canceled']:
        'Item cancelado por motivos diversos. Clique sobre a tag e confira o motivo específico de cada item.',
      ['awaiting_approval']: 'Em aprovação com alçadas internas.',
    },
  },
  ['enums']: {
    ['segmentEnum']: {
      ['defensive']: 'Defensivos',
      ['fertilizer']: 'Fertilizantes',
      ['seed']: 'Sementes',
      ['specialty']: 'Especialidades',
      ['animal_nutrition']: 'Nutrição animal',
      ['grains']: 'Grãos',
      ['unknow']: 'Desconhecido',
    },
    ['orderItemStatusEnum']: {
      ['finished']: 'Completo',
      ['open']: 'Em aberto',
      ['partial']: 'Parcial',
      ['canceled']: 'Cancelado',
      ['awaiting_approval']: 'Aguard. aprov.',
      ['unknow']: 'Desconhecido',
    },
    ['orderPaymentConditionEnum']: {
      ['barter']: 'Barter',
      ['installment']: 'A prazo',
      ['exchange']: 'Permuta',
      ['inCash']: 'À vista',
      ['noPayment']: 'Sem pagamento',
      ['fixedDate']: 'Data fixa',
      ['unknow']: 'Não conhecida',
    },
    ['bondPaymentStatusEnum']: {
      ['expired']: 'Vencidos',
      ['future']: 'A vencer',
      ['paid']: 'Pagos',
    },
    ['financialSelectorFilterEnum']: {
      ['receivable']: 'A receber',
      ['payable']: 'A pagar',
      ['openCredits']: 'Créditos em aberto',
    },
    ['contractTypeEnum']: {
      ['barter']: 'Barter',
      ['purchase']: 'Venda',
      ['toBeSet']: 'A fixar',
    },
    ['paymentMethodEnum']: {
      ['bank_payment_slip']: 'Boleto',
      ['transfer']: 'Transferência',
      ['other']: 'Outro',
    },
    ['modalitySelectorFilterEnum']: {
      ['barter']: 'Permuta',
      ['purchase']: 'Compra',
    },
    ['currencySelectorFilterEnum']: {
      ['BRL']: 'BRL',
      ['USD']: 'USD',
    },
    ['paymentStatusSelectorFilterEnum']: {
      ['open']: 'Em aberto',
      ['partial']: 'Baixa Parcial',
      ['conciliated']: 'Baixado',
    },
    ['payableStatusSelectorFilterEnum']: {
      ['expired']: 'Vencidos',
      ['future']: 'A vencer',
      ['paid']: 'Pagos',
    },
    ['paymentConditionSelectorFilterEnum']: {
      ['barter']: 'Barter',
      ['installment']: 'A prazo',
      ['exchange']: 'Permuta',
      ['inCash']: 'À vista',
      ['noPayment']: 'Sem pagamento',
      ['fixedDate']: 'Data fixa',
    },
    ['receivableStatusSelectorFilterEnum']: {
      ['open']: 'Em aberto',
      ['partial']: 'Receb. parcial',
      ['paid']: 'Recebido',
    },
    ['openCreditsStatusSelectorFilterEnum']: {
      ['open']: 'Em aberto',
      ['partial']: 'Baixa Parcial',
      ['conciliated']: 'Baixado',
    },
    ['creditWarrantyEnum']: {
      ['grainFiduciaryAlienation']: 'Alienação fiduciaria - grãos',
      ['propertyFiduciaryAlienation']: 'Alienação fiduciaria - imóveis',
      ['debtAdmission']: 'Confissão de dívida',
      ['CPR']: 'CPR',
      ['duplicate']: 'Duplicata',
      ['mortgage']: 'Hipoteca',
      ['promissoryNote']: 'Nota promissória',
      ['exchange']: 'Permuta',
    },
    ['payableBondOperationTypeEnum']: {
      ['payment']: 'Pagamento',
      ['advance']: 'Adiantamento',
      ['purchase']: 'Compra',
      ['residual']: 'NF Parcial',
      ['unknown']: 'Desconhecido',
    },
    ['payableBondPaymentStatusEnum']: {
      ['future']: 'A pagar',
      ['expired']: 'Vencido',
      ['paid']: 'Pago',
      ['unknown']: 'Desconhecido',
    },
    ['receivableBondOperationTypeEnum']: {
      ['devolution']: 'Devolução',
      ['receivable']: 'A receber',
      ['received']: 'Pag. Recebido',
    },
    ['userInviteStatusEnum']: {
      ['accepted']: 'Aceito',
      ['rejected']: 'Recusado',
      ['sent']: 'Enviado',
    },
    ['validationCodeTypeEnum']: {
      ['email']: 'e-mail',
      ['phone']: 'celular',
    },
    ['groupedRemittanceSegmentEnum']: {
      ['defensivesAndSpecialties']: 'Defensivos e especialidades',
      ['fertilizers']: 'Fertilizantes',
      ['seeds']: 'Sementes',
      ['others']: 'Outros',
    },
    ['weatherConditionEnum']: {
      ['blizzard']: 'Nevasca',
      ['breezy']: 'Fresco',
      ['clear']: 'Claro',
      ['cloudy']: 'Nublado',
      ['driftingSnow']: 'Nevando',
      ['drizzle']: 'Garoa',
      ['flurries']: 'Rajadas',
      ['foggy']: 'Enevoado',
      ['freezingDrizzle']: 'Garoa Congelante',
      ['freezingRain']: 'Chuva congelante',
      ['hail']: 'Saraiva',
      ['haze']: 'Neblina',
      ['heavyRain']: 'Chuva Pesada',
      ['heavySnow']: 'Neve Pesada',
      ['hot']: 'Quente',
      ['hurricane']: 'Furacão',
      ['iceCrystals']: 'Cristais de Gelo',
      ['isolatedThunderstorms']: 'Tempestades isoladas',
      ['mixedRainAndHail']: 'Chuva misturada com granizo',
      ['mostlyClear']: 'Predomin. Claro',
      ['mostlyCloudy']: 'Predomin. Nublado',
      ['mostlySunny']: 'Principalmente ensolarado',
      ['notAvailable']: 'Indisponível ',
      ['partlyCloudy']: 'Parcialmente Nublado',
      ['rain']: 'Chuva',
      ['rainSleet']: 'Chuva / Granizo',
      ['rainSnow']: 'Chuva / Neve',
      ['sandstorm']: 'Tempestade de Areia',
      ['scatteredShowers']: 'Chuvas dispersas',
      ['scatteredSnowShowers']: 'Pancadas de neve espalhadas',
      ['scatteredThunderstorms']: 'Tempestades Dispersas',
      ['showers']: 'Chuviscos',
      ['sleet']: 'Granizo',
      ['smoke']: 'Fumaça',
      ['snow']: 'Neve',
      ['snowShowers']: 'Pancadas de neve',
      ['strongStorms']: 'Tempestades Fortes',
      ['sunny']: 'Ensolarado',
      ['thunderstorms']: 'Tempestades',
      ['tornado']: 'Tornado',
      ['tropicalStorm']: 'Tempestade tropical',
      ['windy']: 'Ventania',
      ['wintryMix']: 'Água neve',
    },
    ['sprayingRecommendationEnum']: {
      ['recommended']: 'Recomendado',
      ['recommendedAgainst']: 'Não recomendado',
      ['notRecommended']: 'Limitado',
    },
    ['optimalGrowthCurveStatusEnum']: {
      ['aboveOptimalBand']: 'Acima',
      ['belowOptimalBand']: 'Abaixo',
      ['beyondOptimalBand']: 'Além',
      ['newPlot']: 'Novo Talhão',
      ['waitingHarvestClosure']: 'Aguard. Fech. de Safra',
      ['waitingNewHarvest']: 'Aguard. Nova Safra',
      ['noBandForCrop']: 'S/ Banda Cult',
      ['withinOptimalBand']: 'Dentro',
    },
    ['tradeContractTypeEnum']: {
      ['barter']: 'Barter',
      ['purchase']: 'Compra',
      ['toBeSet']: 'A fixar',
    },
    ['paymentConditionEnum']: {
      ['barter']: 'Barter',
      ['inTerm']: 'A prazo',
      ['inCash']: 'À vista',
      ['other']: 'Outro',
      ['partial']: 'Baixa parcial',
    },
    ['notificationContextEnum']: {
      ['contract']: 'Trade',
      ['general']: 'Bem-vindo',
      ['hub']: 'Torq',
      ['order']: 'Pedidos',
      ['payables']: 'A Pagar',
      ['tracking']: 'Entregas',
      ['userInvite']: 'Gestão de usuários',
      ['default']: 'Notificação',
      ['hubObservation']: 'RTR - Relatório em Tempo Real',
      ['hubAlert']: 'TORQ - Alertas',
      ['hubFarmHealth']: 'TORQ',
    },
    ['creditPackageDocumentStatusEnum']: {
      ['pending']: 'Documentos pendentes',
      ['approved']: 'Documentos Validados',
      ['rejected']: 'Documentos Reprovados',
      ['underReview']: 'Documentos Em Análise',
    },
    ['creditPackageDocumentStatusPortalEnum']: {
      ['pending']: 'Enviar docs',
      ['approved']: 'Validado',
      ['rejected']: 'Reenviar',
      ['underReview']: 'Em revisão',
    },
    ['creditPackageDocumentStatusPortalMobileEnum']: {
      ['pending']: 'Enviar docs',
      ['approved']: 'Validado',
      ['rejected']: 'Reenviar',
      ['underReview']: 'Em revisão',
    },
  },
  ['filters']: {
    ['filters']: 'Filtros',
    ['ordersGroupBy']: 'Agrupar por',
    ['harvestReference']: 'Safras',
    ['propertyName']: 'Fazenda',
    ['order']: 'Pedido',
    ['segment']: 'Segmento',
    ['productName']: 'Produto',
    ['orderItemStatus']: 'Status Faturamento',
    ['cultivation']: 'Cultura',
    ['paymentDate']: 'Data de Pagamento',
    ['deliveryDate']: 'Data de Entrega',
    ['contractDeliveryStatus']: 'Status',
    ['searchTerm']: 'Buscar',
    ['deliveryDatePicker']: {
      ['start']: 'Entrega Inicial',
      ['end']: 'Entrega Final',
    },
    ['paymentDatePicker']: {
      ['start']: 'Pagamento Inicial',
      ['end']: 'Pagamento Final',
    },
    ['dueDatePicker']: {
      ['start']: 'Vencimento Inicial',
      ['end']: 'Vencimento Final',
    },
    ['documentDatePicker']: {
      ['start']: 'Emissão Inicial',
      ['end']: 'Emissão Final',
    },
    ['compensationDatePicker']: {
      ['start']: 'Compens. Inicial',
      ['end']: 'Compens. Final',
    },
    ['financialSelector']: '',
    ['paymentStatus']: 'Status',
    ['dueDate']: 'Data de vencimento',
    ['contract']: 'Contrato',
    ['modality']: 'Modalidade',
    ['currency']: 'Moeda',
    ['invoice']: 'Nota Fiscal',
    ['invoiceNumber']: 'Número NF',
    ['clientId']: 'Fazenda',
    ['paymentCondition']: 'Modalidade',
    ['paymentMethod']: 'Método de Pagamento',
    ['receivableStatus']: 'Status',
    ['payableStatus']: 'Status',
    ['operationType']: 'Operação',
    ['dragInstruction']: 'arraste para ver tudo',
    ['placeholder']: 'Digite...',
    ['clear']: 'Limpar filtros',
    ['invalidDate']: 'Data inválida',
    ['noOptions']: 'Nada encontrado',
    ['hideFilters']: 'Ocultar filtros',
  },
  ['financial']: {
    ['title']: 'Financeiro',
    ['bankAccount']: {
      ['agencyNumber']: 'Agência:',
      ['accountNumber']: 'C/c:',
      ['title']: 'Dados bancários',
      ['sinagroBankAccounts']: 'Dados bancários Sinova',
      ['otherBankAccounts']: 'Outros dados bancários Sinova',
      ['cnpj']: 'CNPJ',
      ['identifierCode']: 'Código Identificador',
    },
    ['summary']: {
      ['title']: 'Resumo de contas {{financialSelector}}',
      ['openCreditsTitle']: 'Resumo de {{financialSelector}}',
    },
    ['disclaimer']: {
      ['title']: 'Atenção',
      ['message']:
        'O ambiente está em fase de testes e as informações exibidas podem apresentar divergências em relação as informações reais.',
    },
    ['card']: {
      ['receivables']: {
        ['title']: 'A receber da Sinova:',
        ['description']: 'Valores a receber da Sinova pela Venda de Grãos',
      },
      ['payables']: {
        ['title']: 'A pagar para a Sinova:',
        ['description']:
          'Valores a pagar para a Sinova pela compra de insumos.',
        ['inTerm']: 'A prazo:',
        ['barter']: 'Barter:',
      },
    },
  },
  ['financialReceivable']: {
    ['title']: 'Contas a receber',
    ['summary']: {
      ['open']: 'Em aberto:',
      ['partial']: 'Recebimento parcial:',
      ['paid']: 'Recebido:',
    },
    ['fields']: {
      ['id']: 'Contrato',
      ['productSegment']: 'Segmento',
      ['product']: 'Produto',
      ['propertyName']: 'Fazenda',
      ['totalValue']: 'Valor total',
      ['receivedValueInCents']: 'Valor recebido',
      ['totalOpenValue']: 'Valor em aberto',
      ['paymentStatus']: 'Status',
      ['type']: 'Modalidade',
      ['plannedDeliveryDate']: 'Vencimento',
      ['harvestReference']: 'Safra',
      ['bonds']: 'Títulos',
      ['abbreviated']: {
        ['contract']: 'Contrato',
        ['contractPriceInCents']: 'Valor',
        ['plannedDeliveryDate']: 'Venc.',
      },
    },
    ['accordion']: {
      ['open']: 'Em aberto',
      ['partial']: 'Recebimento parcial',
      ['paid']: 'Recebido',
    },
    ['paidInvoicesFields']: {
      ['title']: 'Títulos',
      ['invoice']: 'Nota fiscal',
      ['totalValue']: 'Valor total',
      ['totalPaidValue']: 'Valor pago',
      ['sinagroId']: 'Id SINOVA',
      ['paymentDate']: 'Data do pagamento',
      ['empty']: 'Ainda não há títulos disponíveis',
    },
    ['v2']: {
      ['title']: 'A Receber da Sinova',
      ['subtitle']: 'Valores a receber da Sinova pela Venda de Grãos',
      ['fields']: {
        ['operationType']: 'Operação',
        ['invoiceNumber']: 'NF',
        ['valueInCents']: 'Valor',
        ['harvestReference']: 'Safra',
        ['documentDate']: 'Data emissão',
        ['dueDate']: 'Vencimento',
        ['compensationDate']: 'Compensação',
        ['contractId']: 'Contrato',
        ['abbreviated']: {
          ['contract']: 'Contrato',
          ['dueDate']: 'Venc.',
        },
      },
      ['summary']: {
        ['title']: 'A Receber',
        ['total']: 'Total:',
        ['receivableTotal']: 'Total a receber:',
      },
    },
    ['empty']: 'Nenhuma conta a receber encontrada',
  },
  ['financialPayable']: {
    ['title']: 'Contas a pagar',
    ['summary']: {
      ['future']: 'A vencer:',
      ['expired']: 'Vencidos:',
      ['paid']: 'Pagos:',
    },
    ['fields']: {
      ['invoiceId']: 'Nota Fiscal',
      ['orderId']: 'Pedido',
      ['propertyName']: 'Fazenda',
      ['totalValueInCents']: 'Valor total',
      ['paidValueInCents']: 'Valor pago',
      ['openValueInCents']: 'Valor em aberto',
      ['dueDate']: 'Vencimento',
      ['paymentCondition']: 'Modalidade',
      ['paymentStatus']: 'Status',
      ['harvestReference']: 'Safra',
      ['information']: 'Informações',
      ['abbreviated']: {
        ['invoiceId']: 'NF',
        ['totalValueInCents']: 'Valor',
        ['dueDate']: 'Venc.',
      },
    },
    ['accordion']: {
      ['expired']: 'Vencidos',
      ['future']: 'A vencer',
      ['paid']: 'Pagos',
    },
    ['paymentFields']: {
      ['title']: 'Pagamentos',
      ['sinagroId']: 'Id SINOVA',
      ['valueInCents']: 'Valor pago',
      ['dueDate']: 'Data do pagamento',
      ['empty']: 'Ainda não há pagamentos disponíveis',
    },
    ['empty']: 'Nenhuma conta a pagar encontrada',
    ['nextBills']: {
      ['title']: 'A pagar',
      ['subtitle']: 'Valores a pagar para a Sinova pela compra de insumos.',
      ['inTerm']: 'A Pagar | A prazo',
      ['barter']: 'A Pagar | Barter',
      ['inCash']: 'NF | Compras à vista',
      ['prus']: 'NF | Programa de Uso (PRUS)',
      ['partial']: 'Baixa Parcial',
      ['other']: 'Outros títulos a pagar',
      ['empty']: 'Nenhuma conta a pagar encontrada',
    },
    ['payments']: {
      ['title']: 'Adiantamentos',
      ['empty']: 'Nenhum adiantamento encontrado',
    },
    ['generalReport']: {
      ['title']: 'Timeline: Pagamentos e adiantamentos',
      ['title2']: 'Relatório geral',
      ['empty']: 'Nenhum pagamento ou adiantamento encontrado',
    },
    ['v2']: {
      ['title']: 'A Pagar para a Sinova',
      ['subtitle']: 'Valores a pagar para a Sinova pela compra de insumos',
      ['fields']: {
        ['operationType']: 'Operação',
        ['documentNumber']: 'Lanç. Cont.',
        ['documentDate']: 'Data Doc.',
        ['compensationDate']: 'Data',
        ['valueInCents']: 'Valor',
        ['dueDate']: 'Vencimento',
        ['compensationDocumentNumber']: 'Doc. Compens.',
        ['harvestReference']: 'Safra',
        ['paymentStatus']: 'Status',
        ['invoice']: 'NF',
        ['abbreviated']: {
          ['dueDate']: 'Venc.',
          ['invoice']: 'NF',
        },
        ['details']: 'Detalhes',
        ['invoiceDetails']: {
          ['invoice']: 'Nota Fiscal',
          ['dueDate']: 'Faturado em',
          ['value']: 'Valor faturado',
          ['id']: 'Número',
        },
        ['paymentCondition']: 'Condição de Pagamento',
      },
      ['summary']: {
        ['total']: 'Total:',
        ['nextBillsTotal']: 'Total a pagar:',
        ['bondValues']: 'Valor de títulos por moeda:',
      },
    },
  },
  ['financialOpenCredit']: {
    ['title']: 'Cŕeditos em Aberto',
    ['collapseMobileTitle']: 'Pagamento',
    ['openCreditsAvailable']: 'Adiantamento disponível',
    ['summary']: {
      ['open']: 'Em aberto:',
      ['conciliated']: 'Baixado:',
      ['partial']: 'Baixa parcial:',
    },
    ['accordion']: {
      ['open']: 'Em aberto',
      ['conciliated']: 'Baixado',
      ['partial']: 'Baixa parcial',
    },
    ['fields']: {
      ['paymentDate']: 'Data de pagamento',
      ['paymentMethod']: 'Método de pagamento',
      ['totalValueInCents']: 'Valor total',
      ['openValueInCents']: 'Valor em aberto',
      ['propertyName']: 'Fazenda',
      ['harvestReference']: 'Safra',
      ['information']: 'Informações',
      ['paymentStatus']: 'Status',
    },
    ['bondsFields']: {
      ['title']: 'Títulos Compensados',
      ['invoiceId']: 'Nota fiscal',
      ['totalValue']: 'Valor total',
      ['sinagroId']: 'Id SINOVA',
      ['dueDate']: 'Data de Compensação',
      ['empty']: 'Ainda não há títulos disponíveis',
    },
    ['empty']: 'Nenhum adiantamento encontrado',
  },
  ['credit']: {
    ['summary']: {
      ['harvestReference']: 'Safra:',
      ['totalApproved']: 'Crédito total aprovado:',
      ['openCreditsAvailable']: 'Adiantamento disponível:',
      ['available']: 'Crédito disponível',
      ['used']: 'Crédito utilizado',
      ['inWallet']: 'Pedidos em carteira',
      ['percentage']: {
        ['available']: 'Disponível',
        ['used']: 'Utilizado',
        ['inWallet']: 'Em carteira',
      },
      ['details']: {
        ['more']: 'Mais detalhes',
        ['less']: 'Menos detalhes',
      },
      ['fields']: {
        ['warranty']: 'Garantia',
        ['totalValueInCents']: 'Valor total',
        ['usedValueInCents']: 'Crédito utilizado',
        ['usedPercentage']: '% utilizada',
        ['total']: 'Total',
      },
      ['empty']: 'Nenhuma informação encontrada',
    },
    ['segment']: {
      ['total']: 'Total:',
    },
  },
  ['notifications']: {
    ['title']: 'Notificações',
    ['empty']: 'Nenhuma notificação',
    ['read']: 'Notificações lidas',
    ['sentAt']: '{{date}} às {{time}}',
    ['at']: 'às',
    ['markAllAsSeen']: 'Marcar todas como lidas',
    ['settings']: {
      ['title']: 'Configurações de notificação push',
      ['titleApp']: 'Configurar notificações',
      ['subtitle']: 'Personalize suas notificações:',
      ['browser']: 'Push Navegador',
      ['app']: 'Push Aplicativo',
      ['trade']: 'Trade',
      ['tradeDesc']:
        'Aviso sobre novos contratos de venda de grãos, novos romaneios recebidos e contrato concluído.',
      ['order']: 'Pedidos',
      ['orderDesc']: 'Aviso sobre novas ordens de venda e pedidos faturados.',
      ['userInvite']: 'Convites',
      ['userInviteDesc']: 'Aviso sobre convites de novos usuários.',
      ['general']: 'Ações no Portal',
      ['generalDesc']: 'Aviso sobre outras ações no Portal.',
      ['payables']: 'A Pagar',
      ['payablesDesc']: 'Aviso sobre títulos com vencimento próximo.',
      ['hub']: 'Torq',
      ['hubDesc']: 'Aviso sobre alertas no Torq.',
      ['tracking']: 'Entregas',
      ['trackingDesc']: 'Aviso sobre alertas de entregas.',
      ['hubObservation']: 'Relatório em Tempo Real',
      ['hubObservationDesc']: 'Aviso sobre o Relatório em Tempo Real.',
      ['hubAlert']: 'TORQ - Alertas',
      ['hubAlertDesc']: 'Aviso sobre alertas no Torq.',
      ['hubFarmHealth']: 'TORQ',
      ['hubFarmHealthDesc']:
        'Aviso sobre curva de crescimento e sensoriamento remoto dos talhões da fazenda.',
      ['whatsapp']: {
        ['title']: 'WhatsApp',
        ['hubAlert']: 'TORQ - Alerta Diário',
        ['hubReport']: 'TORQ - Relatório',
        ['description']: {
          ['hubAlert']: 'Receber alerta diário no whatsapp',
          ['hubReport']: 'Receber relatório no whatsapp',
        },
      },
    },
    ['seeDetails']: 'Ver detalhes',
  },
  ['notVinculated']: {
    ['title']: 'Não encontramos seu CPF/CNPJ no cadastro de clientes Sinova.',
    ['description']:
      'Mas fique tranquilo que nosso time de SAC está pronto para conferir seus dados de cliente ou te auxiliar no cadastro.',
    ['talkToUs']: 'Falar agora',
  },
  ['inviteToApp']: {
    ['titlePart1']: 'Acesse nosso App',
    ['titlePart2']: ' para uma melhor experiência em seu smartphone.',
    ['appButton']: 'App Portal do Cliente',
  },
  ['scheduleAppointment']: {
    ['button']: 'Agende visita técnica ou na loja',
    ['bottomSheet']: 'Agendar visita',
    ['buttonText']: {
      ['part1']: 'Agendar',
      ['part2']: 'Visita técnica ou na loja',
    },
    ['tech']: {
      ['message']:
        'Olá, quero agendar uma visita técnica em minha propriedade.',
      ['title']: 'Agendar visita técnica',
      ['subtitle']: 'com consultor',
    },
    ['store']: {
      ['message']: 'Olá, quero agendar um horário de visita a uma loja Sinova.',
      ['title']: 'Agendar visita na loja',
      ['subtitle']: 'escolher uma loja SinAgro',
    },
  },
  ['tracking']: {
    ['title']: 'Entregas',
    ['empty']: 'No momento, você não tem entregas programadas.',
    ['notFound']: 'Não localizamos entregas programadas para este pedido.',
    ['historyEmpty']: 'Nenhuma entrega foi encontrada.',
    ['historyTitle']: 'Histórico de entregas',
    ['historyFrom']: 'Histórico de pedidos a partir de 12/07/2022',
    ['loadMore']: 'Ver mais',
    ['deliveredAt']: 'Entrega de produtos em',
    ['description']:
      'Selecione um status para ver todos os itens deste segmento de produtos que solicitou entrega.',
    ['status']: {
      ['scheduled']: 'Entrega programada',
      ['handling']: 'Preparando envio',
      ['inTransit']: 'À caminho',
      ['delivered']: 'Entregue',
    },
    ['labels']: {
      ['items']: 'itens',
      ['item']: 'item',
      ['order']: 'Pedido',
      ['remittance']: 'Remessa',
      ['scheduledDate']: 'Previsão de entrega:',
      ['deliveredDate']: 'Entregue:',
      ['downloadReceipts']: 'Comp. Entrega',
      ['downloadInvoices']: 'NF',
    },
    ['loadMoreItems']: 'Carregar mais items',
  },
  ['torq']: {
    ['title']: 'TORQ',
    ['subtitle']: 'Agricultura digital',
    ['details']: {
      ['hourlyDetails']: 'Detalhe hora a hora',
      ['hour']: 'Hora',
      ['precipitation']: 'Precipitação',
      ['pulverization']: 'Pulverização',
      ['temperature']: 'Temperatura',
      ['humidity']: 'Umidade',
      ['wind']: 'Vento',
      ['now']: 'Agora',
      ['today']: 'Hoje',
      ['empty']:
        'Previsão do tempo indisponível no momento. Tente novamente mais tarde.',
    },
    ['disease']: {
      ['title']: 'Pressão de doenças',
      ['today']: 'Hoje',
      ['disease']: {
        ['asian']: 'Risco de Ferrugem Asiática',
        ['brown']: 'Risco de Ferrugem Marrom',
      },
      ['risk']: {
        ['veryLow']: 'Muito baixo',
        ['low']: 'Baixo',
        ['medium']: 'Médio',
        ['high']: 'Alto',
        ['veryHigh']: 'Muito alto',
        ['null']: 'Nulo',
      },
      ['caption']:
        'Risco de aparecimento de doença calculado com base em fatores climáticos.',
      ['empty']:
        'Pressão de doenças indisponível no momento. Tente novamente mais tarde.',
    },
    ['spraying']: {
      ['title']: 'Previsão para Pulverização',
      ['caption']: 'Legenda',
      ['riskCaption']: {
        ['s']: 'Superfície da folha muito molhada',
        ['rs']: 'Risco de umidade da superfície',
        ['h']: 'Temperatura muito alta',
        ['rh']: 'Risco de alta temperatura',
        ['l']: 'Temperatura muito baixa',
        ['rl']: 'Risco de baixa temperatura',
        ['e']: 'Evaporação excessiva',
        ['re']: 'Risco de evaporação',
        ['r']: 'Chuva',
        ['rr']: 'Risco de chuva',
        ['w']: 'Ventoso',
        ['rw']: 'Risco de vento',
        ['rm']: 'Vários riscos',
      },
      ['availableAt']: 'Disponível em',
      ['hours']: 'horas',
      ['days']: 'dias',
      ['empty']:
        'Previsão de pulverização indisponível no momento. Tente novamente mais tarde.',
    },
    ['plot']: {
      ['title']: 'Talhão',
      ['changePlot']: 'Trocar de talhão',
      ['plotList']: 'Lista de talhões',
      ['summary']: {
        ['harvestEstimate']: 'Estimativa de safra',
        ['growthCurve']: 'Curva de crescimento',
        ['ndvi']: 'Saúde da lavoura (NDVI)',
        ['crop']: 'Cultivo',
        ['current']: 'Atual',
        ['caption']: 'Legenda',
      },
      ['filter']: {
        ['growthCurveStatus']: 'Curva de cresc.',
        ['crop']: 'Cultivo',
        ['empty']: 'Nenhum talhão encontrado',
      },
      ['emptyCrop']: 'Nenhum',
    },
    ['farm']: {
      ['summary']: {
        ['total']: 'Total: ',
        ['average']: 'Média: ',
        ['harvestEstimate']: 'Estimativa de safra',
        ['growthCurve']: 'Curva de crescimento (talhões)',
        ['ndvi']: 'Saúde da lavoura (NDVI)',
        ['summary']: 'Resumo',
      },
      ['caption']: {
        ['title']: 'Legendas',
        ['status']: {
          ['within_optimal_band']: 'Dentro',
          ['above_optimal_band']: 'Acima',
          ['below_optimal_band']: 'Abaixo',
          ['beyond_optimal_band']: 'Além',
          ['new_plot']: 'Talhão Novo',
          ['no_band_for_crop']: 'S/ Banda Cult.',
          ['waiting_new_harvest']: 'Aguard. Nova Safra',
          ['waiting_harvest_closure']: 'Aguard. Fech. Safra',
        },
        ['description']: {
          ['within_optimal_band']:
            'Campo dentro da Banda Ideal: O último valor de observação está dentro (ou igual) a faixa ideal.',
          ['above_optimal_band']:
            'Campo acima da Banda Ideal: O último valor de observação é maior que o limite superior da banda ótima.',
          ['below_optimal_band']:
            'Campo abaixo da Banda Ideal: O último valor de observação é menor que o limite inferior da banda ótima.',
          ['beyond_optimal_band']:
            'Campo além da Banda Ideal: Há um valor de observação mais recente, mas não há uma banda ideal (ou seja, a banda termina em uma data anterior).',
          ['new_plot']: 'Talhão recém criado, ainda sem dados.',
          ['no_band_for_crop']:
            'Ainda não foi definida a banda ideal para monitoramento desta cultura.',
          ['waiting_new_harvest']: 'Sem safra para monitoria no momento.',
          ['waiting_harvest_closure']:
            'Talhão aguardnado fechamento ou ciclo de variedade tardio.',
        },
      },
      ['empty']:
        'Resumo da fazenda e do talhão indisponível no momento. Tente novamente mais tarde.',
    },
    ['alert']: {
      ['title']: 'Alertas ativos',
      ['countActiveAlerts']: {
        ['many']: 'alertas ativos nessa localização',
        ['one']: 'alerta ativo nessa localização',
      },
      ['details']: 'Detalhes',
      ['talkToUs']: 'Falar com suporte',
      ['empty']: 'Nenhum alerta ativo nessa localização',
    },
    ['fullScreenMode']: 'Modo Tela Cheia',
    ['share']: {
      ['title']: 'Compartilhar via...',
      ['whatsapp']: 'WhatsApp',
      ['copy']: 'Copiar link',
      ['or']: 'ou',
      ['message']:
        '*TORQ Agricultura Digital* - Acesse o link para visualizar o relatório da',
      ['empty']: 'Link de compartilhamento não encontrado.',
    },
    ['growthCurve']: {
      ['title']: 'Curva de crescimento ideal',
      ['crop']: 'Cultura',
      ['caption']: {
        ['6GVegetationIndex']: 'Índice de Vegetação 6G',
        ['optimalBand']: 'Banda Ideal',
        ['plantingDate']: 'Data de plantio',
      },
      ['label']: {
        ['date']: 'Data:',
        ['vegetationIndex']: 'Índice de Vegetação:',
      },
    },
    ['remoteSensing']: {
      ['title']: 'Sensoriamento remoto - Saúde das plantas',
      ['titleMobile']: 'Sensoriamento remoto - Saúde\ndas plantas',
      ['caption']: {
        ['title']: 'Legenda - Sensoriamento remoto',
        ['deficientVegetation']: 'Vegetação com algum tipo de deficiência',
        ['healthyVegetation']: 'Vegetação moderadamente sadia',
      },
    },
    ['selectCity']: 'Escolher um município',
  },
  ['campaigns']: {
    ['title']: 'Campanhas',
    ['watch']: 'Assistir',
    ['avatarName']: 'Sinova',
  },
  ['report']: {
    ['title']: 'Relatório TORQ',
    ['moreDetailsLink']: 'Acesse o Portal do Cliente para mais detalhes',
    ['empty']: 'Nenhuma informação disponível para esse relatório',
    ['invalidToken']: 'O tempo de visualização desse relatório expirou',
    ['activeAlerts']: 'Alertas ativos hoje',
    ['noActiveAlerts']: 'Nenhum alerta ativo',
    ['weather']: 'Clima',
    ['sprayingForecast']: 'Previsão para Pulverização',
    ['diseaseForecast']: 'Pressão de doenças',
    ['plots']: 'Saúde da Lavoura (talhões)',
    ['farmSummary']: 'Resumo da Fazenda',
    ['disclaimer']: '* Para ver os dados de cada talhão, acesse o TORQ.',
    ['notificationConfigConsent']:
      'Não quer mais receber relatório semanais do TORQ Agricultura Digital? Clique aqui e altere suas preferências nas configurações do perfil do Portal',
  },
  ['systemConfig']: {
    ['availableVersion']:
      'Nova versão do App Portal do Cliente Sinova disponível',
    ['update']: 'Atualizar',
    ['cancel']: 'Cancelar',
  },
  ['pagesTitle']: {
    ['default']: 'Portal do Cliente Sinova',
    ['login']: 'Login | Portal do Cliente Sinova',
    ['inviteSignUp']: 'Cadastro - Usuário convidado',
    ['signUp']: 'Cadastro',
    ['forgotPassword']: 'Esqueci minha senha',
    ['forgotPasswordForm']: 'Esqueci minha senha - Recuperar',
    ['passwordRecovery']: 'Recuperar senha - Nova senha',
    ['validation']: 'Validação de cadastro',
    ['validationCode']: 'Código de validação',
    ['validationCodeSuccess']: 'Cadastro validado com sucesso',
    ['report']: 'Torq - Relatório',
    ['dashboard']: 'Início',
    ['orders']: 'Pedidos',
    ['trade']: 'Trade',
    ['credit']: 'Crédito',
    ['creditPackage']: 'Documentos',
    ['profile']: 'Perfil',
    ['userProfile']: 'Perfil do usuário',
    ['userManagement']: 'Gestão de usuários',
    ['changePassword']: 'Alterar senha',
    ['notifications']: 'Notificações',
    ['tracking']: 'Entregas',
    ['torq']: 'Torq',
    ['financial']: 'Financeiro',
    ['receivable']: 'A receber',
    ['payable']: 'A pagar',
    ['quotation']: 'Preços de Referência',
    ['userInvite']: 'Convidar usuário',
    ['identificationNumbers']: 'Números de identificação Sinova',
    ['managerContact']: 'Contato do gerente',
    ['bankAccount']: 'Dados bancários Sinova',
    ['trackingStatus']: 'Entregas - Status',
    ['services']: 'Serviços',
    ['notificationSettings']: 'Gerenciar notificações',
    ['weatherSelectCity']: 'Torq - Selecionar cidade',
    ['weather']: 'Torq - Clima',
  },
  ['quotation']: {
    ['title']: 'Preços de Referência',
    ['disclaimer']: {
      ['part1']:
        'Todos preços nesta página são valores de referência, sujeitos a variações do mercado.',
      ['part2']:
        'Caso sua localidade não esteja listada nas praças, entre em contato com seu consultor para conferir os Preços de Referência.',
    },
    ['gotIt']: 'Entendi',
    ['source']: 'Fonte:',
    ['lastUpdate']: 'Última atualização:',
    ['BrasiliaTime']: 'UTC-03:00 (Horário de Brasília)',
    ['fields']: {
      ['shipmentDate']: 'Embarque',
      ['price']: {
        ['quotation']: 'Cotação',
        ['BRL']: '(R$/Saca)',
        ['USD']: '(US$/Saca)',
      },
      ['paymentDate']: 'Pagamento',
    },
    ['squareSelect']: 'Selecione a praça para ver as cotações:',
    ['dollarToday']: 'Dólar hoje',
  },
  ['services']: {
    ['title']: 'Serviços',
  },
  ['creditPackage']: {
    ['title']: 'Documentos',
    ['empty']: 'Você não tem documentos pendentes para envio.',
    ['requestedDocuments']: {
      ['title']: 'Documentos Solicitados',
      ['dueDate']: 'Data limite de envio',
      ['modal']: {
        ['title']: 'Documento reprovado',
        ['highlightedText']: 'Reenvie o documento.',
        ['buttonLabel']: 'Entendi',
      },
      ['detailsModal']: {
        ['warningTitle']: 'Documento Reprovado',
        ['buttonLabel']: 'Detalhes',
        ['title']: 'Detalhes',
        ['status']: 'Status',
        ['additionalText']: 'Informações Complementares Descrição',
        ['uploadedBy']: 'Responsavel pelo Upload',
        ['uploadedAt']: 'Data de Upload',
        ['documents']: 'Documentos',
      },
      ['table']: {
        ['document']: 'Documento',
        ['file']: 'Arquivo',
        ['client']: 'Cliente/ID',
        ['uploadedBy']: 'Responsável',
        ['uploadedAt']: 'Data de Upload',
        ['status']: 'Status',
      },
      ['mobileModal']: {
        ['uploadedBy']: 'Responsável',
        ['uploadedAt']: 'Data de Upload',
        ['client']: 'Cliente',
        ['warningTitle']: 'Envie seus documentos',
        ['warningText']: 'Atenção você tem documentos pendentes de envio.',
        ['warningDueDateLabel']: 'Prazo para envio',
      },
      ['errors']: {
        ['maxSizeFile']: 'Arquivos acima de 10 MB não são permitidos',
      },
      ['creditPackageStatus']: {
        ['approved']: 'Documentos validados',
        ['pending']: 'Documentos pendentes',
        ['rejected']: 'Documentos pendentes',
        ['review']: 'Documentos em revisão',
      },
      ['creditPackageDocumentStatus']: {
        ['pending']: 'Enviar docs',
        ['approved']: 'Validado',
        ['rejected']: 'Reenviar',
        ['review']: 'Em revisão',
      },
      ['details']: {
        ['title']: 'Detalhes',
        ['send']: 'Enviar documento',
        ['rejected']: {
          ['title']: 'Documento reprovado',
          ['instruction']: 'Reenvie o documento',
        },
        ['uploadInfo']: {
          ['uploadedBy']: 'Responsável',
          ['uploadedAt']: 'Data do upload',
        },
      },
      ['uploadError']: {
        ['generic']: 'Não foi possível realizar o upload',
        ['camera']: 'Não foi possível acessar a câmera',
        ['fileSize']: 'Arquivos acima de 10 MB não são permitidos',
      },
      ['sendingMessage']: {
        ['title']: 'Envie seus documentos',
        ['message']: 'Atenção você tem documentos pendentes de envio.',
        ['dueDate']: 'Prazo para envio:',
      },
    },
    ['disclaimer']: 'Você tem documentos para enviar.',
    ['disabledDownloadForBackofficeUser']:
      'O download de documentos no Portal só é permitido pelo próprio cliente. O consultor pode fazer o download pela página "Documentos de crédito".',
    ['disabledUploadForBackofficeUser']:
      'O upload de documentos no Portal só é permitido pelo próprio cliente. O consultor pode fazer upload pela página "Documentos de crédito".',
  },
  ['weatherSelectCity']: {
    ['header']: {
      ['title']: 'TORQ',
      ['subtitle']: 'Agricultura digital',
    },
    ['selectCity']: 'Escolha uma cidade para visualizar a previsão do tempo:',
    ['googleAutoComplete']: 'Digite uma cidade',
  },
  ['weather']: {
    ['title']: 'TORQ',
  },
};
