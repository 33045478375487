import { Backdrop, Fade, Grid, Modal as ModalMUI } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton, CloseIcon, CloseText, Container } from './styles';

type ModalFullScreenProps = {
  open: boolean;
  onClose: () => void;
  content: React.ReactElement;
};

const ModalFullScreen = ({ open, onClose, content }: ModalFullScreenProps) => {
  const { t } = useTranslation();

  return (
    <ModalMUI
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={500}>
        <Container>
          <Grid style={{ position: 'absolute', top: 24, right: 24 }}>
            <CloseButton onClick={onClose}>
              <CloseText>{t('commons.close')}</CloseText>
              <CloseIcon size={24} />
            </CloseButton>
          </Grid>

          {content}
        </Container>
      </Fade>
    </ModalMUI>
  );
};

export default ModalFullScreen;
