import React from 'react';
import IconPNG from 'src/assets/icon.png';

import { Container, Avatar, GrandietMask, AvatarIcon } from './styles';

type StoryProps = {
  iframeUrl: string;
  onClick: () => void;
};

const Story = ({ iframeUrl, onClick }: StoryProps) => {
  return (
    <Container onClick={onClick} data-testid={`story-${iframeUrl}`}>
      <GrandietMask>
        <Avatar>
          <AvatarIcon>
            <img src={IconPNG} />
          </AvatarIcon>
        </Avatar>
      </GrandietMask>
      <iframe src={iframeUrl} />
    </Container>
  );
};

export default Story;
