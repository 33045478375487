import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useEffect, useState } from 'react';
import { Campaigns } from 'src/contexts/campaigns';
import { actions, useDispatch, useSelector } from 'src/state/store';
import { analyticsLogger } from 'src/utils/analytics';

export const CampaignsProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [currentCampaignIndex, setCurrentCampaignIndex] = useState(0);

  const campaigns = useSelector((state) => state.shared.campaigns.campaigns);

  const fetchStatus = useSelector(
    (state) => state.shared.campaigns.fetchStatus
  );

  const campaignsTotal = campaigns.length;
  const isLoading = fetchStatus === FetchStatusEnum.Fetching;

  const onChangeCurrentCampaignIndex = (campaignIndex: number) => {
    setCurrentCampaignIndex(campaignIndex);
  };

  const captureInteraction = (campaignId: string, action: 'open' | 'click') => {
    analyticsLogger.campaign(action);
    dispatch(
      actions.shared.interaction.sendCampaignInteraction({
        campaignId,
        action,
      })
    );
  };

  useEffect(() => {
    dispatch(actions.shared.campaigns.getCampaigns());
  }, [dispatch]);

  return (
    <Campaigns.Provider
      value={{
        campaigns,
        campaignsTotal,
        currentCampaignIndex,
        onChangeCurrentCampaignIndex,
        captureInteraction,
        isLoading,
      }}
    >
      {children}
    </Campaigns.Provider>
  );
};

export default CampaignsProvider;
