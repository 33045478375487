import { put } from '@redux-saga/core/effects';
import { auth } from '@sinagro/frontend-shared/firebase';
import { actions as sharedActions } from '@sinagro/frontend-shared/state';
import {
  SharedError,
  errorList,
  unknownError,
  invalidTokenError,
} from '@sinagro/shared/errors';
import axios from 'axios';

import { actions } from '../../../state';
import { AuthenticatedEnum } from '../../types';

interface HandleError {
  error: unknown;
  showToast?: boolean;
  doSignOutWhenInvalidToken?: boolean;
}

export function* handleError({
  error,
  showToast,
  doSignOutWhenInvalidToken = true,
}: HandleError) {
  if (axios.isAxiosError(error) && error.response?.data.errorRef) {
    const data = error.response?.data as SharedError;
    const knownError = errorList.find(
      (errClass) => data.errorRef === errClass.errorRef
    );
    if (
      knownError?.errorRef === invalidTokenError.errorRef &&
      doSignOutWhenInvalidToken
    ) {
      auth().signOut();
      yield put(sharedActions.user.clear());
      yield put(
        sharedActions.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.NotAuthenticated,
        })
      );

      return knownError;
    }
    if (showToast && knownError)
      yield put(actions.errors.add({ error: knownError }));
    return knownError;
  }

  if (showToast) yield put(actions.errors.add({ error: unknownError }));
}
