import { ClickAwayListener } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import IconPNG from 'src/assets/icon.png';
import { useCampaignsContext } from 'src/contexts/campaigns';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import ProgressBar from './progressBar';
import {
  ActionButton,
  ActionLeft,
  ActionRight,
  Avatar,
  AvatarContent,
  AvatarText,
  Container,
  Content,
  Header,
  Story,
} from './styles';

type PlayerProps = {
  onClose: () => void;
};

const Player = ({ onClose }: PlayerProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const {
    campaigns,
    currentCampaignIndex,
    onChangeCurrentCampaignIndex,
    captureInteraction,
    campaignsTotal,
  } = useCampaignsContext();
  const iframeUrl = campaigns[currentCampaignIndex].iframeUrl;
  const [auxClick, setAuxClick] = useState(0);

  const showNextArrow = currentCampaignIndex !== campaignsTotal - 1;
  const showPreviousArrow = currentCampaignIndex !== 0;

  const previousStory = () => {
    onChangeCurrentCampaignIndex &&
      onChangeCurrentCampaignIndex(currentCampaignIndex - 1);
  };

  const nextStory = () => {
    onChangeCurrentCampaignIndex &&
      onChangeCurrentCampaignIndex(currentCampaignIndex + 1);
  };

  const handleCampaign = useCallback(() => {
    if (document?.activeElement?.tagName === 'IFRAME') {
      setAuxClick(auxClick + 1);
      const campaignId = campaigns[currentCampaignIndex].campaignId;
      captureInteraction && captureInteraction(campaignId, 'click');
    }
  }, [campaigns, captureInteraction, currentCampaignIndex, auxClick]);

  useEffect(() => {
    window.focus();
    window.addEventListener('blur', handleCampaign);
    return () => window.removeEventListener('blur', handleCampaign);
  }, [handleCampaign]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Container data-testid="campaign-player">
        {
          <ActionLeft $isMobile={isMobile}>
            {showPreviousArrow && (
              <ActionButton
                onClick={previousStory}
                data-testid="previous-button"
                $isMobile={isMobile}
              >
                <MdChevronLeft />
              </ActionButton>
            )}
          </ActionLeft>
        }
        <Content>
          <Header>
            <ProgressBar />
            <AvatarContent>
              <Avatar>
                <img src={IconPNG} />
              </Avatar>
              <AvatarText>{t('campaigns.avatarName')}</AvatarText>
            </AvatarContent>
          </Header>
          <Story>
            <iframe src={iframeUrl} />
          </Story>
        </Content>
        {
          <ActionRight $isMobile={isMobile}>
            {showNextArrow && (
              <ActionButton
                onClick={nextStory}
                data-testid="next-button"
                $isMobile={isMobile}
              >
                <MdChevronRight />
              </ActionButton>
            )}
          </ActionRight>
        }
      </Container>
    </ClickAwayListener>
  );
};

export default Player;
